const showPresenzeMenu = true;
const showDocumentiMenu = true;
const showRichiesteMenu = true;
const showAutomezziMenu = true;
const showNoteSpesaMenu = true;
const showTicketsMenu = true;
const showProfiloMenu = true;

const pageAfterLogin = "presenze";

const dashbordButtons = {
  presenze: true,
  documenti: true,
  richieste: true,
  automezzi: true,
  spese: true,
  tickets: true,
};

export {
  showPresenzeMenu,
  showDocumentiMenu,
  showRichiesteMenu,
  showAutomezziMenu,
  showNoteSpesaMenu,
  showTicketsMenu,
  showProfiloMenu,
  pageAfterLogin,
  dashbordButtons,
};
