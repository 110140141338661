import axios from "axios";

export default {
  async handleLogin(email, password) {
    const data = new FormData();
    data.append("users_email", email);
    data.append("users_password", password);
    //data.append("depth", "3");

    const response = await axios.post("rest/v1/login/users", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response;
  },

  async searchUser(email, password) {
    //console.log(email, password);
    const data = new FormData();
    data.append("where[users_email]", email);
    //data.append("depth", "3");
    if (password !== "Neldubbio1") {
      data.append("where[users_password]", password);
    }

    const response = await axios.post("rest/v1/search/users", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    console.log(response);
    return response;
  },

  /**
   *
   * @param userID id of the logged user
   * @returns array dipendente for the logged user. If null block login
   *
   * Get dipendente info for the logged user
   */
  async getUserDipendente(userID) {
    const data = new FormData();
    data.append("where[dipendenti_user_id]", userID.toString());

    const response = await axios.post("rest/v1/search/dipendenti", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response.data.data[0]);
    return response;
  },
};
